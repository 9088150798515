
.footer {
  @apply fixed bottom-0 left-0 w-full p-2 place-content-center flex bg-gray-200
}

.nxt-modal {
  @apply fixed inset-0 overflow-y-auto w-screen z-30;

  .nxt-modal-wrapper {
    @apply flex items-end relative top-2 md:justify-center lg:justify-center xl:justify-center;
  }

  .nxt-modal-overlay {
    @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
  }

  .nxt-modal-centering-trick {
    @apply hidden sm:inline-block sm:align-middle sm:h-full;
  }

  .nxt-modal-content {
    @apply fixed top-3 inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all w-full md:w-10/12 lg:w-8/12 xl:w-6/12 max-w-full min-h-[45vh] max-h-[87dvh] mx-auto;
  }

  .nxt-modal-header {
    @apply bg-dark rounded-tl-md rounded-tr-md shadow-lg flex
  }

  .footer {
    @apply bg-gray-400 rounded-none fixed -bottom-10 left-0 w-full p-2 place-content-start flex
  }

  .nxt-modal-footer {
    @apply bg-gray-400 rounded-none fixed -bottom-10 left-0 w-full p-2 place-content-start flex p-3
  }
}

.NgxEditor__Content {
  min-height: 150px !important;
}
